<!-- Экран загрузки-->
<template>
  <div class="mainScreen" v-show="!allReady.all">
    <div class="container">
      <div>LOADING..</div>
      <img src="../assets/img/markers/mot_a.gif" alt="" class="motilyator" />
      <div class="mapStatus">map: {{ allReady.map }}</div>
      <div class="bridgeStatus">vk: {{ allReady.user }}</div>
      <div class="device">divice: {{ allReady.device }}</div>
      <div class="device">position: {{ allReady.userCoords }}</div>
    </div>
  </div>
</template>
<script>
import { initMap } from "@/services/map/InitMap";
import { loadMapScript } from "@/services/map/LoadMapScript";
import drawUser from "@/services/map/drawUser";
import getUserPosition from "@/services/map/getUserPosition";
import updatePosition from "@/services/map/updatePosition";

import { UAParser } from "ua-parser-js";

import bridge from "@vkontakte/vk-bridge";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LoadView",
  data() {
    return {
      allReady: {
        map: false,
        user: false,
        device: false,
        userCoords: false,
        all: false,
      },
      mapReadyStatus: {
        ready: false,
        mapLoad: false,
        mapInit: false,
        msg: "работаем с картой",
      },
      userReadyStatus: {
        ready: false,
        vkConnected: false,
        bridgeInfo: false,
        userInfo: false,
        userCoords: null,
        msg: "connect to VK",
        userCoords: [],
      },
      device: {
        type: "noname",
      },
    };
  },
  computed: {
    ...mapGetters(["MAP_READY_STATUS", "GAME_READY"]),
  },
  mounted() {
    this.workWithMap();
    this.connectBridge();
    this.detectDevice();
    getUserPosition();
  },

  methods: {
    ...mapMutations([
      "SET_MAP_READY_STATUS",
      "SET_USER_NAME",
      "SET_USER_VK_ID",
      "SET_USER_VK_AVA",
      "SET_USER_COORDS",
      "SET_DEVICE_TYPE",
      "SET_GAME_READY",
    ]),
    detectDevice() {
      const parser = new UAParser();
      const result = parser.getResult();

      if (result.device.type === "mobile") {
        this.SET_DEVICE_TYPE("mobile");
        this.device.type = "mobile";
        this.allReady.device = true;
      } else if (result.device.type === "tablet") {
        this.SET_DEVICE_TYPE("tablet");
        this.device.type = "tablet";
        this.allReady.device = true;
      } else {
        this.SET_DEVICE_TYPE("desktop");
        this.device.type = "desktop";
        this.allReady.device = true;
      }
    },
    workWithMap() {
      loadMapScript()
        .then((res) => {
          if (res.status) {
            this.mapReadyStatus.mapLoad = true;
            this.changeMapStatus(res.msg);
            //когда загрузили карту ее надо инициализировать
            initMap("#mapContainer").then((res2) => {
              if (res2.status) {
                this.mapReadyStatus.mapInit = true;
                this.mapReadyStatus.ready = true;
                this.allReady.map = true;
                this.changeMapStatus(res2.msg);
              } else {
                this.changeMapStatus(res2.msg);
              }
              this.updatePosition();
            });
            //не удалось подгрузить скрипт
          } else {
            this.changeMapStatus(res.msg);
          }
        })
        .catch(() => {
          console.log("ошибка загрузки апи карт");
        });
    },
    connectBridge() {
      console.log("коннектим");
      this.changeUserStatus("tryToConnectVk");
      bridge
        .send("VKWebAppInit")
        .then((data) => {
          if (data.result) {
            // Приложение инициализировано
            console.log(data.result);

            this.changeUserStatus("VK connected");
            this.getBridgeinfo();
          } else {
            // Ошибка
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
    getBridgeinfo() {
      ///цепляем инфу про инициализации
      bridge
        .send("VKWebAppGetLaunchParams")
        .then((resp) => {
          if (resp.vk_app_id) {
            // Параметры запуска получены
            this.changeUserStatus("VK initializated");
            console.log(resp);
            this.getBridgeUserInfo(resp.vk_user_id);
            this.getBridgeUserGeo();
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
      // закончили цеплять
    },
    getBridgeUserInfo(vk_user_id) {
      bridge
        .send("VKWebAppGetUserInfo", {
          user_id: vk_user_id,
        })
        .then((data) => {
          if (data.id) {
            this.allReady.user = true;
            // Данные пользователя получены
            this.changeUserStatus("hello" + data.first_name);
            console.log(data);
            this.SET_USER_NAME(data.first_name);
            this.SET_USER_VK_ID(data.id);
            this.SET_USER_VK_AVA(data.photo_100);
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
    updatePosition() {
      getUserPosition().then((geoData) => {
        drawUser([geoData.longitude, geoData.latitude]);
        updatePosition([geoData.longitude, geoData.latitude]);

        this.SET_USER_COORDS([geoData.longitude, geoData.latitude]);
        this.allReady.userCoords = true;
      });
    },
    changeMapStatus(msg) {
      this.mapReadyStatus.msg = msg;
      this.SET_MAP_READY_STATUS(this.mapReadyStatus);
    },
    changeUserStatus(msg) {
      this.userReadyStatus.msg = msg;
    },
  },
  watch: {
    allReady: {
      handler(newValue, oldValue) {
        console.log("allReady изменился:", newValue);
        if (
          newValue.device &&
          newValue.map &&
          newValue.user &&
          newValue.userCoords
        ) {
          this.SET_GAME_READY(true);
          this.allReady.all = true; // Исправлено: присваиваем значение свойству all
        }
      },
      deep: true, // Отслеживаем изменения внутри объекта
    },
  },
};
</script>
<style scoped>
.mainScreen:before {
  background-color: rgba(0, 0, 0, 0.5);
}
.mainScreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url("/src/assets/img/load_screen.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: monospace;
}
.motilyator {
  max-width: 20px;
}
@media only screen and (max-width: 600px) {
  .mainScreen {
    background-position: center;
  }
}
</style>
