const history = {
  state: {
    hisoryVisibleNum: 0,
  },
  mutations: {
    SET_HISTORY_VISIBLE_NUM: (state, num) => {
      //если 0 то скрываем все истории
      state.hisoryVisibleNum = num;
    },
  },
  getters: {
    HISTORY_VISIBLE_NUM: (state) => {
      return state.hisoryVisibleNum;
    },
  },
  actions: {},
};
export default history;
