export function createUser(vk_id, nickname, side, coord_1, coord_2) {
  // Формируем URL с GET-параметром
  //https://vortexsignal.ru/app/api/createUser.php?vk_id=1234&nickname=testMan&side=1&coord_1=12.22&coord_2=0.15&lvl=0&xp=0
  const url = `https://vortexsignal.ru/app/api/createUser.php?vk_id=${vk_id}&nickname=${nickname}&side=${side}&coord_1=${coord_1}2&coord_2=${coord_2}&lvl=0&xp=0`;

  // Выполняем fetch-запрос
  return fetch(url)
    .then((response) => {
      // Проверяем, успешен ли запрос
      if (!response.ok) {
        throw new Error(
          `Ошибка HTTP: ${response.status} ${response.statusText}`
        );
      }
      // Парсим ответ в JSON
      return response.json();
    })
    .then((data) => {
      //console.log("Данные получены:", data);
      return data; // Возвращаем данные
    })
    .catch((error) => {
      console.error("Ошибка при выполнении запроса:", error);
      throw error; // Пробрасываем ошибку дальшеd
    });
}
