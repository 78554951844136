<template>
  <div class="history_container">
    <div class="one_frame frame_1" v-if="currentFrame == 1">
      <span class="frame_text"
        >{{ framesContent[currentFrame - 1].text }}
      </span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_2" v-if="currentFrame == 2">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_3" v-if="currentFrame == 3">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_4" v-if="currentFrame == 4">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_5" v-if="currentFrame == 5">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>

    <div class="one_frame frame_6" v-if="currentFrame == 6">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="img_container">
        <img
          :src="
            require('@/assets/img/history/history1/' +
              framesContent[currentFrame - 1].img)
          "
          class="img"
        />
      </div>
    </div>
    <div class="one_frame frame_7" v-if="currentFrame == 7">
      <span class="frame_text">{{ framesContent[currentFrame - 1].text }}</span>
      <div class="container">
        <div class="choose_container">
          <div class="chooseBtns_container">
            <div
              class="chooseBtn chooseBtn_c"
              @click="chooseC()"
              :class="{ active: active.c }"
            >
              <img
                :src="require('@/assets/img/markers/mot.gif')"
                v-show="!active.c"
                class="choose_logo"
              />
              <img
                :src="require('@/assets/img/markers/mot_a.gif')"
                v-show="active.c"
                class="choose_logo"
              />
              <span>Коннекторы</span>
            </div>

            <div
              class="chooseBtn chooseBtn_i"
              @click="chooseI()"
              :class="{ active: active.i }"
            >
              <img
                :src="require('@/assets/img/markers/got.gif')"
                v-show="!active.i"
                class="choose_logo"
              />
              <img
                :src="require('@/assets/img/markers/got_a.gif')"
                v-show="active.i"
                class="choose_logo"
              />
              <span>Изоляторы</span>
            </div>
          </div>
          <div class="img_7_container">
            <img
              :src="
                require('@/assets/img/history/history1/' +
                  framesContent[currentFrame - 1].img)
              "
              class="img_7"
            />
          </div>
        </div>
        <div class="bottom_container">
          <span class="choose_text">{{ showText }}</span>
          <div class="chooseBtn okBtn" @click="clickOk()">ок</div>
          <span class="attention">
            после выбора поменять класс будет невозможно
          </span>
        </div>
      </div>
    </div>

    <span class="nav-button next" v-show="nextBtn" @click="clickNext()">▶</span>
    <span class="nav-button prev" v-show="prevBtn" @click="clickPrev()">◀</span>
  </div>
</template>

<script>
import { createUser } from "@/services/user/createUser";
import bridge from "@vkontakte/vk-bridge";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "History_1",
  data() {
    return {
      currentFrame: 1,
      nextBtn: true,
      prevBtn: false,
      active: {
        c: false,
        i: false,
      },
      showText: "",
      chooseText: {
        c: "КОННЕКТОРЫ: Контакт с пришельцами — единственный шанс для человечества выйти на новый уровень развития, преодолеть кризисы и объединиться с более развитой цивилизацией.",
        i: "ИЗОЛЯТОРЫ: Любое взаимодействие с неизученным разумом — непредсказуемая угроза, способная разрушить человеческую идентичность или привести к порабощению. Сохраним Землю и её ценности, даже ценой изоляции.",
      },
      framesContent: [
        {
          img: "1.jpg",
          text: "Мы все помним, как  пришел сигнал из глубокого космоса..",
        },
        {
          img: "2.jpg",
          text: "..все телефоны, радиостанции, тв каналы, транслировали призыв о помощи..",
        },
        {
          img: "3.jpg",
          text: "..КРУШЕНИЕ..МОЖЕТЕ СПАСТИ..ОТВЕТЬТЕ..ЗАКРУТИТЕ СИГНАЛ..",
        },
        {
          img: "4.jpg",
          text: "..это разделило нас..",
          contact: false,
        },
        {
          img: "5.jpg",
          text: "..одни увидели в послании угрозу для человечества..",
          contact: false,
        },
        {
          img: "6.jpg",
          text: "..другие  шанс изменить мир к лучшему..",
          contact: true,
        },
        {
          img: "7.jpg",
          text: "..чью сторону выберешь ты?",
          contact: false,
        },
      ],

      autoPlayInterval: null,
    };
  },
  computed: {
    ...mapGetters(["USER"]),
  },

  methods: {
    ...mapMutations(["SET_HISTORY_VISIBLE_NUM"]),
    clickNext() {
      this.vibro();
      this.prevBtn = true;
      if (this.currentFrame == 6) {
        this.nextBtn = false;
      }
      this.currentFrame++;
    },
    clickPrev() {
      this.vibro();
      this.nextBtn = true;
      if (this.currentFrame == 2) {
        this.prevBtn = false;
      }
      this.currentFrame--;
    },

    vibro() {
      bridge
        .send("VKWebAppTapticImpactOccurred", {
          style: "heavy",
        })
        .then((data) => {
          if (data.result) {
            // Вибрация вызвана
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
    chooseC() {
      this.vibro();
      this.showText = this.chooseText.c;
      this.active.c = true;
      this.active.i = false;
    },
    chooseI() {
      this.vibro();
      this.showText = this.chooseText.i;
      this.active.i = true;
      this.active.c = false;
    },
    clickOk() {
      this.vibro();
      if (!this.active.i && !this.active.c) {
        this.showText = "необходимо выбрать сторону";
      } else {
        console.log("заводим пользователя");

        let vk_id = this.USER.vk_id;
        let nickname = this.USER.nickname;
        let side = 0;
        if (this.active.c) {
          side = 1;
        } else {
          side = 2;
        }
        let coord_1 = this.USER.coords[0];
        let coord_2 = this.USER.coords[1];

        createUser(vk_id, nickname, side, coord_1, coord_2)
          .then((data) => {
            //если что то пришло
            if (data.result) {
              this.SET_HISTORY_VISIBLE_NUM(0);
            } else {
              console.log(data);
              // цепляем текущие данные
            }
            // Делаем что-то с данными
          })
          .catch((error) => {
            console.error("Ошибка:", error);
          });
      }
    },
  },

  mounted() {
    //this.startAutoPlay(); // запуск автоматической прокрутки
  },

  beforeUnmount() {
    //this.stopAutoPlay(); // очистка интервала при удалении компонента
  },
};
</script>

<style scoped>
.history_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c1315;
  font-family: monospace;
}

.nav-button {
  position: absolute;
  top: 50%;
  background-color: #869aa57a;
  border: 2px solid #0c1315;
  color: #0c1315;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  font-size: 30px;
  cursor: pointer;
  text-align: center;
}

.nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}
.one_frame {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.frame_text {
  color: #869aa5;
  font-family: monospace;
  font-size: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  height: 10vh;
  display: flex;
  align-items: center;
}
.img_container {
  height: 90vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.img {
  height: 100%;
}

.container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.img_7_container {
  width: 100%;
  overflow: hidden;
}
.img_7 {
  width: 100%;
}
.choose_container {
  color: #869aa5;
  font-family: monospace;
  font-size: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.bottom_container {
  color: #869aa5;
  font-family: monospace;
  font-size: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chooseBtns_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.active {
  border-color: #869aa5;
  color: #000;
}
.chooseBtn {
  border: 2px solid #0a1315;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-height: 20px;
  font-size: 1.5rem;
  font-family: monospace;
  color: #0a1315;
  font-weight: 700;
  min-height: 0px;
}
.attention {
  font-size: 0.9rem;
  color: #ff342f;
}
.chooseBtn_c {
  background: #008ff5;
}
.chooseBtn_i {
  background: #ff8201;
}
.choose_logo {
  max-width: 30px;
}
.active {
  border-color: #fff;
}
.okBtn {
  background-color: #869aa5;
  color: black;
  display: flex;
  justify-content: center;
}
</style>
